import React from 'react';
import { Loader } from 'panamera-react-ui';
import css from './DataLoader.scss';

const DataLoader = () => {
    return (
        <div className={ css.mainWrapper } data-aut-id="data-loader">
            <Loader
                containerSize="16px"
                stroke="3px"
                className={ css.loaderMain }
            />
        </div>
    );
};

export default DataLoader;
