import React, { useCallback, useState, useEffect } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { get } from 'Server';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { withConfig } from 'HOCs/withConfig/withConfig';
import ValidatedNewTextField from 'Components/Fields/Validated/ValidatedNewTextField';
import css from './NumberPlate.scss';
import classnames from 'classnames';
import { REGISTRATION_NUMBER_REGEX_DEFAULT, DATA_AUT_IDS, API_PATH,
    VALUATION_FORM_REGISTRATION, PANAMERA_CLIENT, TRACKING_EVENTS, FIELD_CONSTANTS,
    VALUATION_CAR_REGISTRATION_NO } from '../constants';
import { setValuatonRegistraionResponse, EvaluationConstants } from 'olx-autos-landing-page';
import { IS_DESKTOP } from 'Constants/device.APP_TARGET';
import withRouter from 'HOCs/withRouter';
import { NUMBER_PLATE_REGEX_VALIDATOR } from 'Constants/sellCarO2O';
import DataLoader from '../DataLoader/DataLoader';
import isEmpty from 'lodash/isEmpty';

export const NumberPlate = ({
    intl,
    registrationNumber,
    setRegistrationNumber,
    numberPlatePreApiCallback = () => {},
    numberPlatePostApiCallback = () => {},
    getRegistrationDetails,
    cxeLayout,
    numberPlateProps,
    parentWidgetName,
    setValuatonRegistraionResponse,
    track,
    trackingInfoObj,
    trackingEventName,
    marketConfig,
    router,
    setIsLoading,
    isFetchingRcData,
    numberPlateContainerClassName,
    getSelectFromValue,
    apiConfigData: {
        config: {
            vehicleRegex = ''
        } = {}
    } = {}
}) => {
    const carLabel = intl.formatMessage({ id: 'enterYourCarDetails' });
    const registrationRequired = intl.formatMessage({ id: 'licenseNumberRequired' });
    const registrationError = intl.formatMessage({ id: 'licenseNumberError' });
    const buttonText = intl.formatMessage({ id: 'getCarPrice' });

    const {
        input: {
            placeholder,
            style: inputStyle = {},
            highlightInput,
            errorMsg: {
                text: errorTitle,
                style: errorStyle = {}
            } = {},
            btnSearch: {
                button: {
                    metadata: { style: btnStyle = {}} = {}
                } = {}
            } = {}
        } = {}
    } = numberPlateProps || {};

    const [isNumberValid, updateIsNumberValid] = useState(false);
    const [apiErrMsg, updateApiErrMsg] = useState(null);
    const numberPlatePlaceHolderVal = intl.formatMessage({ id: 'registrationNumberPlaceHolder' });

    useEffect(() => {
        track(TRACKING_EVENTS.name.VALUATION_PAGE_OPEN, {
            select_from: VALUATION_CAR_REGISTRATION_NO
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleChange = useCallback((...args) => {
        updateApiErrMsg(null);
        let isRegNumValid = false;

        if (vehicleRegex && args[2]) {
            const matchedPatterns = args[0].match(new RegExp(vehicleRegex));

            if (matchedPatterns?.indexOf(args[0]) > -1) {
                isRegNumValid = true;
            }
        }
        // FALLBACK IF API FAILS
        else if (!vehicleRegex && args[2] && NUMBER_PLATE_REGEX_VALIDATOR.test(args[0])) {
            isRegNumValid = true;
        }

        if (args[2] && isRegNumValid) {
            updateIsNumberValid(true);
            setRegistrationNumber(args[0].replace(/\s/g, ''));
        }
        else {
            updateIsNumberValid(false);
        }
    }, [vehicleRegex, setRegistrationNumber]);

    const getData = useCallback(() => {
        setIsLoading?.(true);
        numberPlatePreApiCallback();
        const params = {
            regNumber: registrationNumber
        };
        const { TAB_NAME } = EvaluationConstants;

        getRegistrationDetails(params, res => {
            setIsLoading?.(false);
            if ((res.data && res.data.error) || isEmpty(res.data.data.make)) {
                const error = res.data.error || { message: 'Sorry, we couldn\'t find your Car Registration details. Please try again or enter your car details directly in the list below' };

                updateApiErrMsg(error.message);

                numberPlatePostApiCallback({ ok: false, error });
            }
            else {
                const resWithRegNo = {
                    ...res,
                    data: {
                        ...res.data,
                        registrationNumber
                    }
                };

                if (parentWidgetName === `${IS_DESKTOP ? 'desktop' : 'mobile'}-number-plate-widget-buy-tab`) {
                    if (cxeLayout.currentTab !== TAB_NAME.SELL) {
                        router.push(marketConfig.get('landingPageRoutes', 'sell-organic'));
                    }
                    if (typeof window !== 'undefined') {
                        // eslint-disable-next-line no-undef
                        window.scrollTo(0, 0);
                    }
                    setValuatonRegistraionResponse(resWithRegNo);
                }
                if (track && trackingInfoObj && trackingEventName) {
                    track(trackingEventName, trackingInfoObj);
                }
                numberPlatePostApiCallback(res);
            }
        });
    }, [setIsLoading, numberPlatePreApiCallback, registrationNumber, getRegistrationDetails, numberPlatePostApiCallback, parentWidgetName, track, trackingInfoObj, trackingEventName, cxeLayout.currentTab, setValuatonRegistraionResponse, router, marketConfig]);

    function onZoopFocus() {
        if (!placeholder) {
            track(TRACKING_EVENTS.name.VALUATION_ATTRIBUTE_SELECT, {
                select_from: getSelectFromValue(FIELD_CONSTANTS.RC_NUMBER)
            });
        }
    }

    function onZoopBlur(e) {
        if (!placeholder) {
            const { value } = e.target;
            let error_message = '';

            if (value) {
                error_message = REGISTRATION_NUMBER_REGEX_DEFAULT.test(value) ? '' : registrationError;
            }
            else {
                error_message = registrationRequired;
            }

            if (error_message) {
                track(TRACKING_EVENTS.name.VALUATION_ERROR_SHOWN,
                    {
                        select_from: VALUATION_CAR_REGISTRATION_NO,
                        error_message
                    }
                );
            }
        }
    }

    function formatValue(val) {
        return val.toUpperCase();
    }

    return (
        <div className={ classnames(css.registrationContainer, { [numberPlateContainerClassName]: !!numberPlateContainerClassName }) } data-aut-id="number-plate">
            {!numberPlateProps && <label className={ classnames(css.plateHeader, css.plateHeaderV2) }>
                { carLabel }
            </label>}
            <div className={ classnames(css.mainContent, css.mainContentV2) }>
                <div style={ inputStyle } className={ css.textFieldWrapper }>
                    <ValidatedNewTextField
                        id="numberPlate"
                        name="numberPlate"
                        onChange={ handleChange }
                        value={ registrationNumber }
                        validateOnBlur={ false }
                        validateOnChange={ true }
                        requiredErrorMessage={ registrationRequired }
                        regexErrorMessage={ registrationError }
                        regex={ REGISTRATION_NUMBER_REGEX_DEFAULT }
                        required={ true }
                        direction={ 'ltr' }
                        type={ 'text' }
                        placeholder={ placeholder || numberPlatePlaceHolderVal }
                        className={ css.SearchBox }
                        formatValue={ formatValue }
                        dataAutId={ DATA_AUT_IDS.RC_ERROR }
                        data-aut-id="numberPlate"
                        highlightInput={ highlightInput }
                        onFocus={ onZoopFocus }
                        onBlur={ onZoopBlur }
                    />
                    {apiErrMsg && <p style={ errorStyle } className={ css.errorMsg } data-aut-id={ DATA_AUT_IDS.RC_DETAILS_NOT_FOUND_ERROR }>{errorTitle || apiErrMsg}</p>}
                </div>
                <div
                    data-aut-id="btnSearch"
                    style={ btnStyle }
                    onClick={ isNumberValid ? getData : null }
                    className={ classnames(css.searchButton, { [css.disabled]: !isNumberValid }) }
                >
                    <span className={ css.btnText }>{ isFetchingRcData ? <DataLoader /> : buttonText }</span>
                </div>
            </div>
        </div>
    );
};

NumberPlate.propTypes = {
    numberPlatePostApiCallback: PropTypes.func,
    numberPlatePreApiCallback: PropTypes.func,
    getRegistrationDetails: PropTypes.func,
    registrationNumber: PropTypes.string,
    setRegistrationNumber: PropTypes.func,
    cxeLayout: PropTypes.object,
    numberPlateProps: PropTypes.object,
    setValuatonRegistraionResponse: PropTypes.func,
    parentWidgetName: PropTypes.string,
    intl: PropTypes.shape({
        formatMessage: PropTypes.func.isRequired
    }).isRequired,
    track: PropTypes.func,
    trackingInfoObj: PropTypes.object,
    trackingEventName: PropTypes.string,
    marketConfig: PropTypes.object,
    router: PropTypes.object.isRequired,
    setIsLoading: PropTypes.func,
    numberPlateContainerClassName: PropTypes.string,
    getSelectFromValue: PropTypes.func,
    isFetchingRcData: PropTypes.bool,
    apiConfigData: PropTypes.object
};

export const mapDispatchToProps = dispatch => ({
    getRegistrationDetails: (params, callback) => dispatch(get(API_PATH.REGISTRATION_DETAILS_V2, VALUATION_FORM_REGISTRATION, params, PANAMERA_CLIENT)).then(res => callback(res)),
    setValuatonRegistraionResponse: res => dispatch(setValuatonRegistraionResponse(res))
});

export const mapStateToProps = state => ({
    cxeLayout: state.cxeLayout,
    apiConfigData: state.bookAppointment.disclaimer.data
});

export default compose(
    injectIntl,
    withConfig('marketConfig'),
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(NumberPlate);
