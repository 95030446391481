/* eslint-disable react/jsx-no-bind */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import css from './ToolTip.scss';
import { IS_MOBILE } from 'Constants/device.APP_TARGET';

export const ToolTip = ({
    children,
    className,
    attributes,
    tooltip
}) => {
    const [isShown, setIsShown] = useState(false);

    const handleClick = event => {
        if (attributes.onClick) {
            attributes.onClick(event);
        }
        if (IS_MOBILE) {
            setIsShown(!isShown);
        }
    };

    return (
        <span
            className={ classNames(
                css.tooltip,
                { [css.showTooltip]: isShown },
                className
            ) }
            { ...attributes }
            data-tooltip={ tooltip }
            onClick={ handleClick }
        >
            {children}
        </span>
    );
};

ToolTip.propTypes = {
    children: PropTypes.node,
    attributes: PropTypes.object,
    className: PropTypes.string,
    tooltip: PropTypes.string
};

ToolTip.defaultProps = {
    children: null,
    attributes: {},
    className: ''
};

export default ToolTip;
