/* eslint-disable max-lines */
/* global window */
import React, { useState, useEffect, useCallback } from 'react';
import withRouter from 'HOCs/withRouter';
import { get, post } from 'Server';
import _getValue from 'lodash/get';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';

import withTrack from 'HOCs/withTrack/withTrack';
import { withConfig } from 'HOCs/withConfig/withConfig';
import withLocalStorage from 'HOCs/withLocalStorage/withLocalStorage';
import withLocation from 'HOCs/withLocation/withLocation';
import CookieManager from 'Helpers/cookies';
import { getSelectedLocationSelector } from './../../olxautos/helpers/locationHelpers';
import {
    getAPIPathName,
    goToUrl
} from '../helpers/helpers';
import { getQueryRedirectionParams } from 'Selectors/olxAutos';

import {
    UserSession,
    fetchLayout,
    EvaluationConstants,
    LayoutHelper,
    LayoutSelector } from 'olx-autos-landing-page';
import { configSelector } from 'Selectors/config';
import { IS_DESKTOP, PLATFORM } from 'Constants/device.APP_TARGET';
import { isEmpty } from 'Helpers/objects';
import { SI_VALUATION_FIELDS_MAP } from '../SelfInspection/constants';
import { Helmet } from 'react-helmet-async';
import { VALUATION_FLOW_ROUTE, SELL_OLD_CAR_ROUTE } from '../../olxautos/constants/layout';
import { renderHeadTitle } from './helpers';

import {
    SHOW_ALL_BTN_KEY,
    VALUATION_USER_CONTACT_DETAILS,
    API_PATH,
    PANAMERA_CLIENT,
    FIELD_CONSTANTS,
    TRACKING_EVENTS,
    VALUATION_FORM_DATA_VALUES,
    VALUATION_FORM_PRICE_PREDICTION,
    VALUATION_FORM_LEAD_ID,
    SI_FLOW_START_COOKIE,
    DEFAULT_SELECTED_TAB,
    CAR_REGISTRATION_NUMBER,
    VALUATION_TABS,
    VALUATION_CAR_REGISTRATION_NO,
    FORM_FIELD_API_VERSION_L2Q,
    PREV_SEO_PATH
    // NUMBER_PLATE_LENGTH
} from './constants';
import { IS_MOBILE } from 'Constants/device.APP_TARGET';

import { fetchFormFields, toggleShowAllCTA, updateRegistrationUrlApiCalled } from './actions';
import { fetchDisclaimer } from '../BookAppointmentV2/actions';
import { getFlowType } from '../BookAppointmentV2/selectors';
import { clearAppointmentDetails } from '../BookAppointmentV2/actions';

import ValuationFlowView from './ValuationFlowView/ValuationFlowView.APP_TARGET';

const ValuationFlowV2 = props => {
    const {
        getFormFields,
        data: {
            tabs = []
        },
        metadata,
        router,
        marketConfig,
        intl,
        getDataValues,
        localStorage,
        location,
        track,
        trackingOrigin,
        isGpsEnabled,
        selectedLocation,
        landingURLSearch,
        valuationFormFields,
        valuationMakePairs,
        carDetails
    } = props;
    const isSeoFlow = location.pathname === `/${SELL_OLD_CAR_ROUTE}`;
    const isHandleSeoLink = marketConfig.get('olxAutos', 'isHandleSeoLink');
    const valuationRoute = isHandleSeoLink ? `${VALUATION_FLOW_ROUTE}/${SELL_OLD_CAR_ROUTE}` : VALUATION_FLOW_ROUTE;
    const submenuInitialValue = isSeoFlow || carDetails?.toggleSubMenu || false;
    const defaultTab = localStorage.getItem(DEFAULT_SELECTED_TAB) || _getValue(metadata, 'defaultTab', VALUATION_TABS.CAR_DETAILS);
    const trackingMap = {
        car_details: TRACKING_EVENTS.page.carDetails,
        rc_number: VALUATION_CAR_REGISTRATION_NO
    };

    const numberPlate = localStorage.getItem(FIELD_CONSTANTS.NUMBER_PLATE);
    const lastSelectedSection = UserSession.getLastSelectionOption() || carDetails?.lastSelectionOption;
    const [displayFields, setDisplayFields] = useState(valuationFormFields);
    const [isGetPriceHandlerFired, setIsGetPriceHandlerFired] = useState(false);

    const getSelectecTabIdx = useCallback(() => {
        const idx = displayFields.findIndex(({ id }) => id === lastSelectedSection);

        return idx < 0 ? 0 : idx;
    }, [displayFields, lastSelectedSection]);

    const [userDataValues, setUserDataValues] = useState(carDetails?.userData || {}); // {make: ''Hyundai}
    const [stepDataValues, updateStepDataValues] = useState(valuationMakePairs); // All Values
    const [selectedTabIndex, setSelectedTabIndex] = useState(getSelectecTabIdx());
    const [registrationNumber, updateRegistrationNumber] = useState(numberPlate || '');
    const [isFetchingData, setIsFetchingData] = useState(false);
    const [isFetchingRcData, setIsFetchingRcData] = useState(false);
    const [apiParam, updateApiParam] = useState(lastSelectedSection || displayFields[selectedTabIndex]?.id);
    const [isError, setIsError] = useState(false);
    const [showSubmenu, toggleShowSubmenu] = useState(submenuInitialValue);
    // const [isLoadingOnSSR, setIsLoadingOnSSR] = useState(true);

    const handleToggleShowSubmenu = useCallback(val => {
        toggleShowSubmenu(val);
    }, []);

    const getSelectFromValue = useCallback(key => {
        const isInValidKey = key === '' || key == null;

        if (isInValidKey) {
            return `${TRACKING_EVENTS.page.carDetails}`;
        }
        else if (key === FIELD_CONSTANTS.SELL_URGENCY_KEY) {
            return `${TRACKING_EVENTS.page.carDetails}_urgency`;
        }
        return `${TRACKING_EVENTS.page.carDetails}_${key && key.toLowerCase()}`;
    }, []);

    const setRegistrationNumber = useCallback(val => {
        updateRegistrationNumber(val);
        localStorage.setItem(CAR_REGISTRATION_NUMBER, val);
    }, [localStorage]);

    const getTrackingFields = useCallback(() => {
        const valuationUserValues = UserSession.getUserEvaluationFormValues() || userDataValues;
        const bookingIndexId = localStorage.getItem(TRACKING_EVENTS.field.bookingIndexId);

        const defaultProperty = {
            origin: trackingOrigin,
            flow_type: valuationUserValues && valuationUserValues.make ? TRACKING_EVENTS.flow.old : TRACKING_EVENTS.flow.new,
            booking_index_id: bookingIndexId,
            ...LayoutHelper.getAcquisitionChannelTrackingValues(),
            user_type: CookieManager.readCookie(EvaluationConstants.REPEAT_USER_COOKIE_NAME) ? 2 : 1,
            reason: CookieManager.readCookie(EvaluationConstants.SELLER_STATE_COOKIE) || 'INITIAL',
            gps_on_off: isGpsEnabled,
            ...getSelectedLocationSelector(selectedLocation)
        };

        if (valuationUserValues) {
            defaultProperty.item_make = valuationUserValues[`${FIELD_CONSTANTS.MAKE_KEY}-${FIELD_CONSTANTS.DISPLAY_VALUE}`];
            defaultProperty.item_model = valuationUserValues[`${FIELD_CONSTANTS.MODEL_KEY}-${FIELD_CONSTANTS.DISPLAY_VALUE}`];
            defaultProperty.item_variant = valuationUserValues[`${FIELD_CONSTANTS.VARIANT_KEY}-${FIELD_CONSTANTS.DISPLAY_VALUE}`];
            defaultProperty.item_year = valuationUserValues[`${FIELD_CONSTANTS.YEAR_KEY}-${FIELD_CONSTANTS.DISPLAY_VALUE}`];
            defaultProperty.item_mileage = valuationUserValues[`${FIELD_CONSTANTS.MILEAGE_KEY}-${FIELD_CONSTANTS.DISPLAY_VALUE}`];
            defaultProperty.item_num_owners = valuationUserValues[`${FIELD_CONSTANTS.FIRST_OWNER}-${FIELD_CONSTANTS.DISPLAY_VALUE}`];
            defaultProperty.item_registeredstate = valuationUserValues[`${FIELD_CONSTANTS.REGISTERED_STATE_KEY}-${FIELD_CONSTANTS.DISPLAY_VALUE}`];
            defaultProperty.item_transmission = valuationUserValues[`${FIELD_CONSTANTS.TRANSMISSION}-${FIELD_CONSTANTS.DISPLAY_VALUE}`];
            defaultProperty.item_bodytype = valuationUserValues[`${FIELD_CONSTANTS.BODY_TYPE}-${FIELD_CONSTANTS.DISPLAY_VALUE}`];
            defaultProperty.item_facelift = valuationUserValues[`${FIELD_CONSTANTS.FACE_LIFT}-${FIELD_CONSTANTS.DISPLAY_VALUE}`];
            defaultProperty.item_seat = valuationUserValues[`${FIELD_CONSTANTS.SEAT_TYPE}-${FIELD_CONSTANTS.DISPLAY_VALUE}`];
            defaultProperty.item_engine = valuationUserValues[`${FIELD_CONSTANTS.ENGINE_TYPE}-${FIELD_CONSTANTS.DISPLAY_VALUE}`];
            defaultProperty.item_wheeldrive = valuationUserValues[`${FIELD_CONSTANTS.WHEEL_DRIVE}-${FIELD_CONSTANTS.DISPLAY_VALUE}`];
            defaultProperty.item_sellingurgency = valuationUserValues[`${FIELD_CONSTANTS.SELL_URGENCY_KEY}-${FIELD_CONSTANTS.DISPLAY_VALUE}`];
        }

        return defaultProperty;
    }, [isGpsEnabled, localStorage, selectedLocation, trackingOrigin, userDataValues]);

    const trackWrapper = useCallback((eventName, extraProperty) => {
        const defaultProperty = {
            ...getTrackingFields(),
            ...extraProperty,
            ...getQueryRedirectionParams(landingURLSearch, location)
        };
        const lead_id = localStorage.getItem(FIELD_CONSTANTS.LEAD_ID);

        if (lead_id) {
            defaultProperty.lead_id = lead_id;
        }
        track(eventName, defaultProperty);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getTrackingFields, localStorage, track]);

    const setUserEvaluationValues = useCallback(userVal => {
        const isValuationFlowPage = !IS_DESKTOP && router.location.pathname.includes(valuationRoute);

        UserSession.saveUserEvaluationFormValues(userVal, isValuationFlowPage);
    }, [router.location.pathname, valuationRoute]);
    const removeDisplayValue = (userDataValues, removePhone) => {
        return Object.keys(userDataValues).reduce((acc, val) => {
            if (val != FIELD_CONSTANTS.PHONE_KEY && removePhone) {
                const key = val.replace(`-${FIELD_CONSTANTS.DISPLAY_VALUE}`, 'Value');

                acc[key] = userDataValues[val];
            }
            return acc;
        }, {});
    };

    const removeSelectedDataById = id => {
        const data = {
            ...userDataValues
        };

        delete data[id];
        delete data[`${id}-${FIELD_CONSTANTS.DISPLAY_VALUE}`];

        setUserEvaluationValues(data);
        setUserDataValues(data);
    };

    const goToStep = useCallback((idx, nonSkippableIdxCheck = true) => {
        if (isFetchingData || (idx > selectedTabIndex && nonSkippableIdxCheck)) {
            return;
        }

        let curIdx = idx;

        if (isNaN(curIdx)) {
            curIdx = selectedTabIndex - 1;
        }

        if (curIdx < 0) {
            UserSession.deleteEvaluationFormValues(); // ERASING COOKIE FOR SI FLOW IF VALUATION STARTED AGAIN
            UserSession.deleteLastSelectionOption();
            // redirect to sell-car in mobile
            if (IS_MOBILE) {
                const path = marketConfig.get('landingPageRoutes', 'sell-organic');

                goToUrl(path, {}, {}, router);
            }
            return;
        }

        const { id } = displayFields[curIdx];
        const modifiedUserDataValues = { ...userDataValues };
        let ctr = curIdx;

        while (++ctr < displayFields.length) {
            const val = displayFields[ctr].id;

            delete modifiedUserDataValues[val];
            delete modifiedUserDataValues[`${val}-${FIELD_CONSTANTS.DISPLAY_VALUE}`];
        }

        setUserDataValues(modifiedUserDataValues);
        setUserEvaluationValues(modifiedUserDataValues);
        setSelectedTabIndex(curIdx);
        updateApiParam(id);
        setIsError(false);
        UserSession.saveLastSelectionOption(id);
        trackWrapper(TRACKING_EVENTS.name.VALUATION_TAB_ATTRIBUTE,
            {
                select_from: getSelectFromValue(lastSelectedSection),
                field_name: id && id.toLowerCase(),
                chosen_option: ''
            }
        );
        trackWrapper(TRACKING_EVENTS.name.VALUATION_PAGE_OPEN,
            { select_from: id === FIELD_CONSTANTS.PHONE_KEY ? VALUATION_USER_CONTACT_DETAILS : getSelectFromValue(id) }
        );
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [displayFields, getSelectFromValue, isFetchingData, marketConfig, router, selectedTabIndex, setUserEvaluationValues, trackWrapper, userDataValues]);

    const onGetPriceClick = useCallback(() => {
        setIsGetPriceHandlerFired(true);
        // tracking
        // trackWrapper(TRACKING_EVENTS.name.VALUATION_VALUE_CAR, { select_from: getSelectFromValue(lastSelectedSection) });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const goToNextStep = ({
        id,
        key,
        label
    }) => {
        const pathname = router.location.pathname;
        let data = { ...userDataValues };
        let nxtIdx = 0;
        let trackingKey = TRACKING_EVENTS.name.VALUATION_TAB_SEE_MORE;
        let choosenOption = '';
        let selectFromField = getSelectFromValue();
        const currentFieldValue = data[id];

        if (key != SHOW_ALL_BTN_KEY) {
            data = {
                ...data,
                [id]: key,
                [`${id}-${FIELD_CONSTANTS.DISPLAY_VALUE}`]: label
            };

            nxtIdx = selectedTabIndex + 1;
            trackingKey = TRACKING_EVENTS.name.VALUATION_ATTRIBUTE_COMPLETE;
            selectFromField = getSelectFromValue(id);
            choosenOption = !([FIELD_CONSTANTS.PHONE_KEY, FIELD_CONSTANTS.PLATE_TYPE].includes(key)) && label;
        }

        if (currentFieldValue && currentFieldValue != key) {
            // localStorage.setItem(CAR_REGISTRATION_NUMBER, '');
            // const { dependentUpdate } = displayFields[selectedTabIndex];

            // dependentUpdate.forEach(val => {
            //     delete data[val];
            //     delete data[`${val}-${FIELD_CONSTANTS.DISPLAY_VALUE}`];
            // });

            // deleting subsequent fields' value
            let ctr = selectedTabIndex;

            while (++ctr < displayFields.length) {
                const val = displayFields[ctr].id;

                delete data[val];
                delete data[`${val}-${FIELD_CONSTANTS.DISPLAY_VALUE}`];
            }
        }

        const isLastStep = nxtIdx === displayFields.length;

        trackWrapper(trackingKey,
            {
                select_from: selectFromField,
                field_name: id,
                chosen_option: choosenOption
            }
        );

        setUserEvaluationValues(data);
        setUserDataValues(data);

        if (!isLastStep) {
            const nxtIdxField = displayFields[nxtIdx].id;

            setSelectedTabIndex(nxtIdx);
            updateApiParam(nxtIdxField);
            UserSession.saveLastSelectionOption(nxtIdxField);
            setIsError(false);
            trackWrapper(TRACKING_EVENTS.name.VALUATION_PAGE_OPEN,
                { select_from: getSelectFromValue(displayFields[nxtIdx].id) }
            );
        }

        const isAutoRedirectionPageAllowed = () => {
            // Disabling auto-redirection for number plate step
            // if (apiParam != FIELD_CONSTANTS.PLATE_TYPE) {
            //     return true;
            // }
            // auto redirection only if plate length equals max value
            // const numberPlateLength = _getValue(data, 'plate.length', 0);
            // return numberPlateLength === NUMBER_PLATE_LENGTH.MAX;
            return apiParam != FIELD_CONSTANTS.PLATE_TYPE;
        };

        if (isLastStep && isAutoRedirectionPageAllowed()) {
            onGetPriceClick();
        }

        if ((pathname === marketConfig.get('landingPageRoutes', 'sell-organic') || pathname === marketConfig.get('landingPageRoutes', 'sell-ppc') || pathname === '/') && IS_MOBILE) {
            const { EVALUATION_STATES } = EvaluationConstants;

            UserSession.setUserEvaluationState(EVALUATION_STATES.VALUATION_STARTED);
            goToUrl(valuationRoute, {}, {}, router);
        }
    };
    const onGetPriceCtaClick = () => {
        trackWrapper(TRACKING_EVENTS.name.VALUATION_VALUE_CAR,
            {
                select_from: TRACKING_EVENTS.page.carDetails
            }
        );

        const pathname = router.location.pathname;

        if ((pathname === marketConfig.get('landingPageRoutes', 'sell-organic') || pathname === marketConfig.get('landingPageRoutes', 'sell-ppc') || pathname === '/' || pathname === '/olxmobbi') && IS_MOBILE) {
            const { EVALUATION_STATES } = EvaluationConstants;

            UserSession.setUserEvaluationState(EVALUATION_STATES.VALUATION_STARTED);
            goToUrl(valuationRoute, {}, {}, router);
        }
    };

    const onBackBtnClick = () => {
        const currentStepId = displayFields[selectedTabIndex].id;

        trackWrapper(TRACKING_EVENTS.name.VALUATION_BACK_PRESS, {
            select_from:
            currentStepId === FIELD_CONSTANTS.PHONE_KEY
                ? VALUATION_USER_CONTACT_DETAILS
                : getSelectFromValue(currentStepId)
        });
        goToStep();
    };

    const reloadPage = useCallback(() => {
        window.location.reload();
    }, []);

    const onCloseHandler = () => {
        const currentStepId = displayFields[selectedTabIndex].id;

        trackWrapper(TRACKING_EVENTS.name.VALUATION_CROSS_PRESS, {
            select_from:
            currentStepId === FIELD_CONSTANTS.PHONE_KEY
                ? VALUATION_USER_CONTACT_DETAILS
                : getSelectFromValue(currentStepId)
        });
        goToStep(-1);
    };

    const resetData = () => {
        setIsFetchingData(false);
        setSelectedTabIndex(0);
        setUserDataValues({});
        setUserEvaluationValues({});
        updateStepDataValues(valuationMakePairs);
        updateApiParam(FIELD_CONSTANTS.MAKE_KEY);
        UserSession.saveLastSelectionOption(FIELD_CONSTANTS.MAKE_KEY);
    };

    const getFieldDataValues = async () => {
        try {
            const valuationUserValues = UserSession.getUserEvaluationFormValues() || userDataValues;
            const updatedUserDataValues = removeDisplayValue({ ...valuationUserValues }, true);
            const pathname = router.location.pathname;

            updatedUserDataValues.platform = `web-${PLATFORM}`;
            updatedUserDataValues.version = '1.0.0';

            // if (apiParam === FIELD_CONSTANTS.MAKE_KEY && !isEmpty(valuationMakePairs)) {
            //     updateStepDataValues(valuationMakePairs);
            //     return;
            // }

            if (apiParam === FIELD_CONSTANTS.PLATE_TYPE) {
                updateStepDataValues({
                    id: FIELD_CONSTANTS.PLATE_TYPE,
                    popular_list: [],
                    list: []
                });
            }

            if ((pathname === marketConfig.get('landingPageRoutes', 'sell-organic') || pathname === marketConfig.get('landingPageRoutes', 'sell-ppc') || pathname === '/') && IS_MOBILE && apiParam != FIELD_CONSTANTS.MAKE_KEY) {
                resetData();
                return;
            }

            if (apiParam !== FIELD_CONSTANTS.PHONE_KEY && apiParam !== FIELD_CONSTANTS.PLATE_TYPE) {
                // abortController.abort();

                // TILL DATE WE WERE USING HARD-CODING API PATH FOR ATTRIBUTES For Ex. /api/rocket/valuate/{apiParam}
                // AS THESE URL ALREADY COMES WITH THE FORM-FIELDS BUT WITH V1
                // BELOW FUNCTION NOW USES valueUrl Prop FORM_FIELDS RES AND REMOVE V1 FROM THE STRING
                const apiPathName = getAPIPathName(displayFields, apiParam);

                setIsFetchingData(true);
                const res = await getDataValues(apiPathName, updatedUserDataValues);

                setIsFetchingData(false);
                if (res.ok) {
                    updateStepDataValues(res.data?.data || {});
                }
                else {
                    setIsError(true);
                }
            }
        }
        catch (err) {
            setIsFetchingData(false);
            setIsError(true);
        }
    };

    const numberPlatePreApiCallback = useCallback(() => {
        trackWrapper(TRACKING_EVENTS.name.VALUATION_TAP_NEXT, { select_from: VALUATION_CAR_REGISTRATION_NO });
        setIsFetchingRcData(true);

        CookieManager.eraseCookie(SI_FLOW_START_COOKIE); // ERASING COOKIE FOR SI FLOW IF VALUATION STARTED AGAIN
    }
    , [trackWrapper]);

    useEffect(() => {
        if (apiParam !== FIELD_CONSTANTS.MAKE_KEY && !showSubmenu && isHandleSeoLink && displayFields.length !== selectedTabIndex + 1) {
            toggleShowSubmenu(true);
        }
    }, [apiParam, displayFields, isHandleSeoLink, lastSelectedSection, selectedTabIndex, showSubmenu]);

    const numberPlatepostApiCallback = res => {
        const { data, ok } = res;
        const userData = {};

        if (ok) {
            const { data: carDetailsRes } = data;
            let lastSelectedIdx = 0;

            for (let i = 0; i < displayFields.length; i++) {
                const key = displayFields[i].id;

                if (carDetailsRes[key] === undefined) {
                    lastSelectedIdx = i;
                    break;
                }
                else {
                    userData[key] = carDetailsRes[key].code;
                    userData[`${key}-displayValue`] = carDetailsRes[key].label;
                }
            }

            if (IS_MOBILE) {
                goToUrl(valuationRoute, {}, {}, router);
            }
            else if (isHandleSeoLink) {
                goToUrl('/sell', {}, {}, router);
            }

            UserSession.saveLastSelectionOption(displayFields[lastSelectedIdx].id);
            setUserEvaluationValues(userData);
            setUserDataValues(userData);
            setSelectedTabIndex(lastSelectedIdx);
            updateApiParam(displayFields[lastSelectedIdx].id);
        }
        else {
            trackWrapper(TRACKING_EVENTS.name.VALUATION_ERROR_SHOWN,
                { select_from: VALUATION_CAR_REGISTRATION_NO, error_message: res?.error?.message }
            );
        }
        setIsFetchingRcData(false);
    };

    const saveInspectionFieldsMap = useCallback(formFieldRes => {
        const inspectionParamMap = {};

        formFieldRes.forEach(field => {
            inspectionParamMap[field.selfInspectionKey] = field.id;
        });
        localStorage.setItem(SI_VALUATION_FIELDS_MAP, JSON.stringify(inspectionParamMap));
    }, [localStorage]);

    const getFormFieldsApiRes = useCallback(() => getFormFields(res => {
        if (res.ok) {
            const { data } = res.data;
            const formFieldRes = [...data];

            setDisplayFields(formFieldRes);
            saveInspectionFieldsMap(formFieldRes);
        }
        else {
            setIsError(true);
        }
        setIsFetchingData(false);
    }), [getFormFields, saveInspectionFieldsMap]);

    useEffect(() => {
        if (isError) {
            trackWrapper(TRACKING_EVENTS.name.VALUATION_ERROR_SHOWN,
                { select_from: getSelectFromValue(lastSelectedSection), errorMessage: intl.formatMessage({ id: 'reviews_error_desc' }) }
            );
        }
    }, [getSelectFromValue, intl, isError, lastSelectedSection, trackWrapper]);

    useEffect(() => {
        setSelectedTabIndex(getSelectecTabIdx());
    }, [displayFields, getSelectecTabIdx]);

    useEffect(() => {
        // const selectedTab = localStorage.getItem(DEFAULT_SELECTED_TAB);
        const localCarRegistraionNumber = localStorage.getItem(CAR_REGISTRATION_NUMBER);

        setUserDataValues(prev => (UserSession.getUserEvaluationFormValues() || prev || {}));

        // setIsLoadingOnSSR(false);
        clearAppointmentDetails();

        if (!displayFields.length) {
            setIsFetchingData(true);
            getFormFieldsApiRes();
        }

        saveInspectionFieldsMap(displayFields);

        const isValuationFlowPage = !IS_DESKTOP && router.location.pathname.includes(valuationRoute);

        const selectFrom = isValuationFlowPage ? getSelectFromValue(lastSelectedSection || FIELD_CONSTANTS.MAKE_KEY) : (trackingMap[defaultTab] || trackingMap.car_details);

        trackWrapper(TRACKING_EVENTS.name.VALUATION_PAGE_OPEN,
            { select_from: selectFrom }
        );

        // set car registration number from LS
        if (localCarRegistraionNumber) {
            updateRegistrationNumber(localStorage.getItem(CAR_REGISTRATION_NUMBER));
        }

        if (isEmpty(UserSession.getUserEvaluationFormValues() || userDataValues)) {
            toggleShowSubmenu(false);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!isFetchingRcData) {
            getFieldDataValues();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [apiParam, router.location.pathname, isFetchingRcData]);

    useEffect(() => {
        if (isGetPriceHandlerFired) {
            let lastEmptyField;

            for (let i = 0; i < displayFields.length; i++) {
                if (!userDataValues[displayFields[i].id]) {
                    lastEmptyField = i;
                    break;
                }
            }

            if (lastEmptyField === undefined) {
                const { location } = router;

                if (isHandleSeoLink && location.search) {
                    localStorage.setItem(PREV_SEO_PATH, `${location.pathname}${location.search}`);
                }

                goToUrl('/olxmobbi/contact-details', {}, {}, router);
            }
            else {
                goToStep(lastEmptyField, false);
            }
            setIsGetPriceHandlerFired(false);
        }
    }, [userDataValues, isGetPriceHandlerFired, displayFields, router, goToStep, isHandleSeoLink, localStorage]);

    const valuationProps = {
        showBackBtn: true,
        showCloseBtn: true,
        stepId: displayFields[selectedTabIndex]?.id,
        title: displayFields[selectedTabIndex]?.label || '',
        currentStep: selectedTabIndex + 1,
        totalSteps: displayFields.length,
        navItems: displayFields,
        selectedFormValues: userDataValues, // selectedFormValues or userDataValues
        stepDataValues: {
            ...stepDataValues,
            fieldDescription: displayFields[selectedTabIndex]?.labelDesc || ''
        },
        isFetchingData,
        isError,
        isSearchRequired: displayFields[selectedTabIndex]?.isSearchRequired,
        selectedTabIndex, // selectedNavItem
        registrationNumber,
        isFetchingRcData,
        tabs,
        defaultTab,
        metadata,
        lastSelectedSection,
        onGetPriceClick,
        numberPlatePreApiCallback,
        numberPlatepostApiCallback,
        setRegistrationNumber,
        trackWrapper,
        goToStep,
        goToNextStep,
        onClose: onCloseHandler,
        onBackBtnClick,
        getSelectFromValue,
        reloadPage,
        removeSelectedDataById,
        showSubmenu,
        toggleShowSubmenu: handleToggleShowSubmenu,
        displayFields,
        onGetPriceCtaClick
    };

    return (
        <>
            {isHandleSeoLink
                && <Helmet>
                    <title>
                        { renderHeadTitle(displayFields, apiParam, userDataValues) }
                    </title>
                </Helmet>
            }
            <ValuationFlowView { ...props } valuationProps={ valuationProps } />
        </>
    );
};

ValuationFlowV2.propTypes = {
    getFormFields: PropTypes.func,
    getDataValues: PropTypes.func,
    getPricePredictions: PropTypes.func,
    getLeadId: PropTypes.func,
    router: PropTypes.object,
    localStorage: PropTypes.object,
    data: PropTypes.object,
    metadata: PropTypes.object,
    intl: PropTypes.object,
    marketConfig: PropTypes.object,
    fetchLayoutFromCXE: PropTypes.func,
    location: PropTypes.object,
    showAllCta: PropTypes.bool,
    toggleShowAllCTA: PropTypes.func,
    track: PropTypes.func,
    trackingOrigin: PropTypes.string,
    isGpsEnabled: PropTypes.bool,
    selectedLocation: PropTypes.object,
    landingURLSearch: PropTypes.string,
    updateRegistrationUrlApiCalled: PropTypes.func,
    disclaimer: PropTypes.object,
    fetchDisclaimer: PropTypes.func,
    flowType: PropTypes.string,
    registrationResponse: PropTypes.object,
    user: PropTypes.object,
    globalConfig: PropTypes.object,
    valuationFormFields: PropTypes.array,
    valuationMakePairs: PropTypes.object,
    clearAppointmentDetails: PropTypes.func,
    carDetails: PropTypes.object
};

export const mapStateToProps = (state, ownProps) => ({
    trackingOrigin: LayoutSelector.getTrackingOrigin(state, ownProps.location, ownProps.marketConfig),
    showAllCta: state.valuationFlow.showAllCTA,
    landingURLSearch: state.track.landingURLSearch,
    disclaimer: state.bookAppointment.disclaimer.data,
    flowType: getFlowType(state, ownProps.location, ownProps?.marketConfig?.get()),
    registrationResponse: state.cxeLayout.registrationResponse,
    user: state?.users?.collections?.['users#/api/users/link-account'] || state?.users?.collections?.['users#/api/users/me'],
    globalConfig: configSelector(state),
    valuationFormFields: state.valuationFlow.valuationFormFields,
    valuationMakePairs: state.valuationFlow.valuationMakePairs,
    carDetails: state.valuationFlow.carDetails
});

export const mapDispatchToProps = dispatch => {
    return {
        getFormFields: callback => dispatch(fetchFormFields(FORM_FIELD_API_VERSION_L2Q)).then(res => callback(res)),
        getDataValues: (apiPath, userDataValues) => dispatch(get(apiPath, `${VALUATION_FORM_DATA_VALUES}_${apiPath}`, userDataValues, PANAMERA_CLIENT)),
        getPricePredictions: userDataValues => dispatch(post(API_PATH.PRICE_PREDICTION, VALUATION_FORM_PRICE_PREDICTION, userDataValues, PANAMERA_CLIENT)),
        getLeadId: (userDataValues, isOTPEnabled) => {
            const path = `${API_PATH.LEAD_API}?isOTPEnabled=${!!isOTPEnabled}`;

            return dispatch(post(path, VALUATION_FORM_LEAD_ID, userDataValues, PANAMERA_CLIENT));
        },
        fetchLayoutFromCXE: (params, layoutSource) => dispatch(fetchLayout(params, layoutSource)),
        toggleShowAllCTA: flag => dispatch(toggleShowAllCTA(flag)),
        fetchDisclaimer: () => dispatch(fetchDisclaimer()),
        updateRegistrationUrlApiCalled: val => dispatch(updateRegistrationUrlApiCalled(val)),
        clearAppointmentDetails: () => dispatch(clearAppointmentDetails())
    };
};

export default compose(
    withConfig('marketConfig'),
    withRouter,
    withTrack,
    connect(mapStateToProps, mapDispatchToProps),
    injectIntl,
    withLocalStorage,
    withLocation
)(ValuationFlowV2);
