/* eslint-disable react/no-multi-comp */
/* eslint-disable react-perf/jsx-no-new-object-as-prop */
/* eslint-disable react/jsx-no-bind */
import React, { useState } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { GetPriceCta } from '../ValuationFlowView/ValuationFlowView.mobile';
import NumberPlate from '../NumberPlate/NumberPlate';
import { renderPopularList } from '../ValuationFieldsValue/ValuationFieldsValue';
import { SHOW_ALL_BTN_KEY, DEFAULT_SELECTED_TAB, TRACKING_EVENTS, VALUATION_CAR_REGISTRATION_NO } from '../constants';
import SITE_CODES from 'Constants/siteCodes';
import css from './ValuationFlowWidgetV2.scss';

export const TabsView = ({
    tabs,
    selectedTab,
    onTabClick
}) => {
    if (tabs.length < 2) {
        return <></>;
    }

    return (
        <div className={ css.tabs } data-aut-id="valuation-flow-tabs">
            {
                tabs.map(({ key, label, id }) => {
                    const cls = classnames(css.tab, {
                        [css.selected]: selectedTab === id
                    });

                    return (
                        <button
                            key={ key }
                            className={ cls }
                            onClick={ () => onTabClick(id) }
                            data-aut-id={ `tab-${key}` }>
                            { label }</button>
                    );
                })
            }
        </div>
    );
};

const ValuationFlowWidgetV2 = ({
    tabs,
    defaultTab,
    title,
    stepId,
    onFieldValueClick,
    selectedFormValues,
    popularList,
    registrationNumber,
    numberPlatePreApiCallback,
    numberPlatepostApiCallback,
    setRegistrationNumber,
    getSelectFromValue,
    intl,
    marketConfig,
    trackWrapper,
    isFetchingRcData,
    localStorage,
    metadata,
    displayFields,
    onGetPriceCtaClick
}) => {
    const [selectedTab, setSelectedTab] = useState(defaultTab || tabs[0]?.id);
    const { style = {}} = metadata;
    const trackingMap = {
        car_details: TRACKING_EVENTS.page.carDetails,
        rc_number: VALUATION_CAR_REGISTRATION_NO
    };
    const siteCode = marketConfig.get('siteCode');

    const onTabClick = id => {
        setSelectedTab(id);
        const previousSelectedTabId = localStorage.getItem(DEFAULT_SELECTED_TAB) || defaultTab;

        localStorage.setItem(DEFAULT_SELECTED_TAB, id);

        trackWrapper(TRACKING_EVENTS.name.VALUATION_TAB_ATTRIBUTE,
            {
                select_from: trackingMap[previousSelectedTabId],
                field_name: previousSelectedTabId
            }
        );
    };

    const renderTabContent = () => {
        if (selectedTab === 'rc_number') {
            return (
                <NumberPlate
                    registrationNumber={ registrationNumber }
                    setRegistrationNumber={ setRegistrationNumber }
                    intl={ intl }
                    numberPlatePreApiCallback={ numberPlatePreApiCallback }
                    numberPlatePostApiCallback={ numberPlatepostApiCallback }
                    getSelectFromValue={ getSelectFromValue }
                    track={ trackWrapper }
                    marketConfig={ marketConfig }
                    isFetchingRcData={ isFetchingRcData }
                />
            );
        }

        const popularListWithShowAll = popularList.slice();

        const SHOW_ALL_BTN = {
            key: SHOW_ALL_BTN_KEY,
            label: intl.formatMessage({ id: 'more' }),
            icon: ''
        };
        const TOGGLE_SHOW_ALL_BTN_COUNTRIES = [SITE_CODES.OLX_IN, SITE_CODES.OLX_TR, SITE_CODES.OLX_CL, SITE_CODES.OLX_ID, SITE_CODES.OLX_MX];
        const isShowAllBtnVisible = !TOGGLE_SHOW_ALL_BTN_COUNTRIES.includes(siteCode);

        if (popularListWithShowAll.length && isShowAllBtnVisible) {
            popularListWithShowAll.push(SHOW_ALL_BTN);
        }
        const ctaText = isShowAllBtnVisible ? 'getCarPrice' : 'viewBrands';

        return (
            <>
                <div className={ css.popularListWrapper } data-aut-id="widget-popular-list-wrapper">
                    <div className={ css.widgetTitle } data-aut-id="widget-title">{ title }</div>
                    {
                        renderPopularList({
                            id: stepId,
                            onFieldValueClick,
                            selectedFormValues,
                            popularList: popularListWithShowAll,
                            showDivider: false,
                            marketConfig,
                            displayFields
                        })
                    }
                </div>
                <GetPriceCta
                    onClick={ onGetPriceCtaClick }
                    isCtaDisabled={ false }
                    text={ ctaText }
                />

            </>
        );
    };

    return (
        <div className={ css.mainWrapper } data-aut-id="valuation-flow-widget" style={ style }>
            <div className={ css.content }>
                <TabsView tabs={ tabs } selectedTab={ selectedTab } onTabClick={ onTabClick } />
                { renderTabContent() }
            </div>
        </div>
    );
};

ValuationFlowWidgetV2.propTypes = {
    tabs: PropTypes.array,
    defaultTab: PropTypes.string,
    title: PropTypes.string,
    stepId: PropTypes.string,
    selectedFormValues: PropTypes.object,
    popularList: PropTypes.array,
    onFieldValueClick: PropTypes.func,
    registrationNumber: PropTypes.string,
    numberPlatePreApiCallback: PropTypes.func,
    numberPlatepostApiCallback: PropTypes.func,
    setRegistrationNumber: PropTypes.func,
    getSelectFromValue: PropTypes.func,
    intl: PropTypes.object,
    marketConfig: PropTypes.object,
    localStorage: PropTypes.object,
    metadata: PropTypes.object,
    trackWrapper: PropTypes.func,
    isFetchingRcData: PropTypes.bool,
    displayFields: PropTypes.array,
    onGetPriceCtaClick: PropTypes.func
};

TabsView.propTypes = {
    tabs: PropTypes.array,
    selectedTab: PropTypes.string,
    onTabClick: PropTypes.func
};

export default ValuationFlowWidgetV2;
