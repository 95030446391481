/* eslint-disable react/no-multi-comp */
/* eslint-disable react/jsx-no-bind */
import React, { useRef, useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { injectIntl } from 'react-intl';
import { FormattedMessage as Translation } from 'react-intl';
import get from 'lodash/get';

import Icon from 'Components/ThematicIcon/ThematicIcon';
import ValuationFieldsValue from '../ValuationFieldsValue/ValuationFieldsValue';
import Button from 'Components/Button/Button';
import css from './FieldsNavigationBar.desktop.scss';
import { FIELD_CONSTANTS } from '../constants';
import ToolTip from 'Components/ToolTip/ToolTip';
import CookieManager from 'Helpers/cookies';
import { VALUATION_TRACKING_EVENTS } from '../../../constants/sellCarO2O';

const TTHT_EXP_DAYS = 10;

const NavListItem = ({
    label,
    selectedValue,
    isSelected,
    showSubmenu,
    submenuRef,
    submenu,
    onClick,
    itemClass,
    fieldId,
    onGetPriceClick,
    priceButtonStatus,
    attrKey,
    intl
}) => {
    const stepId = get(submenu, 'stepDataValues.id', '');
    const values = { placeholder: get(submenu, 'stepDataValues.list[0].label', '') };

    const showPlaceholderValue = isSelected && !selectedValue && (stepId === fieldId);

    const valuationFieldValueProps = {
        getContactPageCtaStatus: !priceButtonStatus,
        onGetPriceClick,
        ...submenu
    };

    const ttht = CookieManager.readCookie('ttht');

    if (ttht === undefined) {
        // eslint-disable-next-line no-magic-numbers
        CookieManager.createCookie('ttht', 'true', TTHT_EXP_DAYS);
    }

    const showToolTip = () => {
        if ((ttht === 'true' || ttht === undefined) && attrKey === FIELD_CONSTANTS.MAKE_KEY && !showSubmenu) {
            return (<ToolTip
                className={ css.tooltipht }
            >
                <span>{intl.formatMessage({ id: 'tooltipHelpText' })}</span>
            </ToolTip>);
        }
        return null;
    };

    return (
        <li onClick={ onClick } className={ itemClass } data-aut-id="valuation-field-item">
            { showToolTip() }
            <div className={ css.title }>{label}</div>
            { selectedValue && <div className={ css.selectedValue }>{selectedValue}</div> }
            { showPlaceholderValue ? <div className={ css.placeholderValue }><Translation id="examplePlaceholder" values={ values } /></div> : <></> }
            { (isSelected && showSubmenu) ? <div className={ css.submenu } ref={ submenuRef } data-aut-id="valuation-field-submenu"><ValuationFieldsValue { ...valuationFieldValueProps } /></div> : <></> }
        </li>
    );
};

const NavList = ({
    listRef,
    submenuRef,
    navItems,
    selectedFormValues,
    selectedTabIndex,
    onNavItemClick,
    showSubmenu,
    submenu,
    toggleShowSubmenu,
    showLeftArrow,
    showRightArrow,
    onScrollHandler,
    onGetPriceClick,
    priceButtonStatus,
    handleVerticalScroll,
    intl
}) => {
    if (!navItems.length) {
        return <></>;
    }

    /* istanbul ignore next */
    const leftArrowHandler = () => {
        onScrollHandler('left');
    };

    /* istanbul ignore next */
    const rightArrowHandler = () => {
        onScrollHandler('right');
    };

    return (
        <>
            {/* istanbul ignore next */ showLeftArrow && <button className={ classnames(css.navigationArrow, css.leftArrow) } onClick={ leftArrowHandler }>
                <Icon
                    color="primary"
                    data-aut-id="icon-left-arrow"
                    icon="arrowLeft"
                    size={ 48 }
                />
            </button>}
            {/* istanbul ignore next */ showRightArrow && <button className={ classnames(css.navigationArrow, css.rightArrow) } onClick={ rightArrowHandler }>
                <Icon
                    color="primary"
                    data-aut-id="icon-right-arrow"
                    icon="arrowRight"
                    size={ 48 }
                />
            </button>}
            <div className={ css.navigationWrapper }>
                <ul
                    ref={ listRef }
                    className={ css.fieldsNavBar }
                    data-aut-id="valuation-fields">
                    {
                        navItems.map(({
                            id,
                            key,
                            label
                        }, idx) => {
                            const selectedValue = selectedFormValues[`${id}-${FIELD_CONSTANTS.DISPLAY_VALUE}`];
                            const isSelected = idx === selectedTabIndex;

                            const itemClass = classnames(css.item, {
                                [css.selected]: isSelected,
                                [css.withValue]: selectedValue != undefined
                            });

                            const clickHandler = () => {
                                if ((isSelected && showSubmenu) || (idx > selectedTabIndex)) {
                                    return;
                                }
                                if (!showSubmenu) {
                                    CookieManager.createCookie('ttht', 'false', TTHT_EXP_DAYS);
                                    toggleShowSubmenu(true);
                                }
                                onNavItemClick(idx);
                                if (key === FIELD_CONSTANTS.MAKE_KEY && isSelected) {
                                    handleVerticalScroll();
                                }
                            };

                            return (<NavListItem
                                attrKey={ key }
                                label={ label }
                                selectedValue={ selectedValue }
                                isSelected={ isSelected }
                                showSubmenu={ showSubmenu }
                                submenuRef={ submenuRef }
                                submenu={ submenu }
                                onClick={ clickHandler }
                                itemClass={ itemClass }
                                fieldId={ navItems[selectedTabIndex].id }
                                priceButtonStatus={ priceButtonStatus }
                                onGetPriceClick={ onGetPriceClick }
                                intl={ intl }
                                key={ key }
                            />);
                        })
                    }
                </ul>
            </div>
        </>
    );
};

const PriceButton = ({
    onClick
}) => {
    return (
        <Button
            type="primaryBtn"
            className={ css.priceButton }
            onClick={ onClick }
            data-aut-id="next_button"
        >
            <Translation id="getCarPrice" />
        </Button>
    );
};

const FieldsNavigationBar = ({
    navItems,
    selectedTabIndex,
    selectedFormValues,
    onNavItemClick,
    submenu,
    onGetPriceClick,
    trackPageOpenInModal,
    showSubmenu: showMenuDialog,
    toggleShowSubmenu: toggleShowMenuDialog,
    intl
}) => {
    const listRef = useRef(null);
    const submenuRef = useRef(null);
    const sid = useRef(null);
    const [showLeftArrow, setShowLeftArrow] = useState(false);
    const [showSubmenu, toggleShowSubmenu] = useState(showMenuDialog);
    const [showRightArrow, setShowRightArrow] = useState(false);
    const lastSelectedRef = useRef(selectedTabIndex);
    const trackWrapper = get(submenu, 'trackWrapper');

    useEffect(() => {
        toggleShowSubmenu(showMenuDialog);
    }, [showMenuDialog]);

    const handleToggleShowSubmenu = useCallback(val => {
        toggleShowMenuDialog(val);
        toggleShowSubmenu(val);
    }, [toggleShowMenuDialog]);

    useEffect(() => {
        const listNode = listRef.current;
        const onListScroll = () => {
            const { parentElement } = listNode;

            // eslint-disable-next-line no-magic-numbers
            setShowRightArrow(parentElement.scrollLeft + parentElement.offsetWidth + 10 < listNode.scrollWidth);
            // eslint-disable-next-line no-magic-numbers
            setShowLeftArrow(parentElement.scrollLeft > 5);
        };

        if (listNode) {
            listRef.current.children[selectedTabIndex]?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
            lastSelectedRef.current = selectedTabIndex;
            listNode.parentElement.addEventListener('scroll', onListScroll);
            onListScroll();
        }

        if (selectedTabIndex > 0) {
            CookieManager.createCookie('ttht', 'false', TTHT_EXP_DAYS);
        }

        return () => {
            clearTimeout(sid);

            /* istanbul ignore else */
            if (listNode) {
                listNode.parentElement.removeEventListener('scroll', onListScroll);
            }
        };
    }, [selectedTabIndex]);

    /* istanbul ignore next */
    const onScrollHandler = dir => {
        const val = dir === 'left' ? -1 : 1;
        let idx = lastSelectedRef.current + val;

        if (idx === navItems.length) {
            --idx;
        }

        if (idx < 0) {
            ++idx;
        }
        lastSelectedRef.current = idx;
        listRef.current.children[lastSelectedRef.current]?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
    };

    const toggleShakeAnimation = () => {
        /* istanbul ignore else */
        if (!sid.current) {
            submenuRef.current.classList.add(css.shake);
        }
        sid.current = setTimeout(() => {
            submenuRef.current.classList.remove(css.shake);
            sid.current = null;
        // eslint-disable-next-line no-magic-numbers
        }, 1000);
    };

    const hasAllUserdataValues = () => {
        let lastEmptyField;

        for (let i = 0; i < navItems.length; i++) {
            if (!selectedFormValues[navItems?.[i]?.id]) {
                lastEmptyField = i;
                break;
            }
        }

        return lastEmptyField == null;
    };

    const priceButtonStatus = () => {
        const isValSelected = selectedFormValues[navItems?.[selectedTabIndex]?.id];

        return showSubmenu && !isValSelected || !showSubmenu && !hasAllUserdataValues();
    };

    const priceClickHandler = () => {
        const isValSelected = selectedFormValues[navItems?.[selectedTabIndex]?.id];

        trackWrapper(VALUATION_TRACKING_EVENTS.name.VALUATION_VALUE_CAR, { select_from: VALUATION_TRACKING_EVENTS.page.carDetails });

        CookieManager.createCookie('ttht', 'false', TTHT_EXP_DAYS);
        if (showSubmenu && !isValSelected) {
            toggleShakeAnimation();
            return;
        }
        if (!showSubmenu && !hasAllUserdataValues()) {
            handleToggleShowSubmenu(true);
            trackPageOpenInModal(navItems[selectedTabIndex].id);
            return;
        }

        onGetPriceClick();
    };

    const handleVerticalScroll = () => {
        const listNode = listRef.current;
        const { parentElement } = listNode;

        parentElement.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
    };

    return (
        <>
            <NavList
                listRef={ listRef }
                submenuRef={ submenuRef }
                navItems={ navItems }
                selectedFormValues={ selectedFormValues }
                selectedTabIndex={ selectedTabIndex }
                onNavItemClick={ onNavItemClick }
                showSubmenu={ showSubmenu }
                submenu={ submenu }
                toggleShowSubmenu={ handleToggleShowSubmenu }
                showLeftArrow={ showLeftArrow }
                showRightArrow={ showRightArrow }
                onScrollHandler={ onScrollHandler }
                onGetPriceClick={ onGetPriceClick }
                priceButtonStatus={ priceButtonStatus() }
                handleVerticalScroll={ handleVerticalScroll }
                intl={ intl }
            />
            <PriceButton onClick={ priceClickHandler } />
        </>
    );
};

FieldsNavigationBar.propTypes = {
    navItems: PropTypes.array.isRequired,
    selectedFormValues: PropTypes.object,
    selectedTabIndex: PropTypes.number,
    onNavItemClick: PropTypes.func,
    submenu: PropTypes.object,
    onGetPriceClick: PropTypes.func,
    trackPageOpenInModal: PropTypes.func,
    showSubmenu: PropTypes.bool,
    toggleShowSubmenu: PropTypes.func,
    intl: PropTypes.object
};

NavListItem.propTypes = {
    label: PropTypes.string,
    selectedValue: PropTypes.string,
    isSelected: PropTypes.bool,
    showSubmenu: PropTypes.bool,
    submenuRef: PropTypes.object,
    submenu: PropTypes.object,
    onClick: PropTypes.func,
    itemClass: PropTypes.string,
    fieldId: PropTypes.string,
    onGetPriceClick: PropTypes.func,
    priceButtonStatus: PropTypes.bool,
    attrKey: PropTypes.string,
    intl: PropTypes.object
};

NavList.propTypes = {
    listRef: PropTypes.object,
    submenuRef: PropTypes.object,
    navItems: PropTypes.array,
    selectedFormValues: PropTypes.object,
    selectedTabIndex: PropTypes.number,
    onNavItemClick: PropTypes.func,
    toggleShowSubmenu: PropTypes.func,
    showSubmenu: PropTypes.bool,
    showRightArrow: PropTypes.bool,
    showLeftArrow: PropTypes.bool,
    submenu: PropTypes.object,
    onScrollHandler: PropTypes.func,
    onGetPriceClick: PropTypes.func,
    priceButtonStatus: PropTypes.bool,
    handleVerticalScroll: PropTypes.func,
    intl: PropTypes.object
};

PriceButton.propTypes = {
    onClick: PropTypes.func
};

export default injectIntl(FieldsNavigationBar);
