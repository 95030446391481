/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useState } from 'react';
import Icon from 'Components/ThematicIcon/ThematicIcon';
import PropTypes from 'prop-types';
import ValidatedNewTextField from 'Components/Fields/Validated/ValidatedNewTextField';
import css from './SearchBar.scss';
import { TRACKING_EVENTS } from '../constants';

export const SearchBar = ({
    selectedTabIndex,
    getSearchValue,
    displayFields,
    intl,
    dataValues,
    id,
    trackWrapper,
    getSelectFromValue
}) => {
    const [firstVal, updateFirstValue] = useState('');

    useEffect(() => {
        const val = dataValues[id] ? dataValues[id]?.list?.[0]?.label : '';

        if (val) {
            updateFirstValue(val);
        }
    }, [dataValues, id]);

    const searchBarPlaceHolder = intl.formatMessage({ id: 'exampleValue' }, { value: firstVal });

    const handleChange = (...args) => {
        getSearchValue(args[0]);
    };

    let label;

    if (displayFields) {
        label = intl.formatMessage({ id: displayFields[selectedTabIndex].id }).toLowerCase();
    }
    const carLabel = label ? intl.formatMessage({ id: 'carLabel' }, { label }) : undefined;

    function triggerFocusTracking(e) {
        e.preventDefault();
        trackWrapper(TRACKING_EVENTS.name.VALUATION_SEARCH_START, {
            field_name: id,
            select_from: getSelectFromValue(id)
        });
    }

    return (
        <div className={ css.searchContainer } data-aut-id="search-bar">
            { carLabel && <label className={ css.label }>{carLabel}</label> }
            <div className={ css.mainContent }>
                <div className={ css.textFieldWrapper }>
                    <ValidatedNewTextField
                        id="searchBar"
                        name="searchBar"
                        onChange={ handleChange }
                        validateOnChange={ true }
                        placeholder={ searchBarPlaceHolder }
                        type={ 'text' }
                        data-aut-id="searchBar"
                        className={ css.SearchBox }
                        onFocus={ triggerFocusTracking }
                    />
                </div>
                <div data-aut-id="btnSearch" className={ css.searchButton }>
                    <span>
                        <Icon
                            icon="search"
                            size={ 24 }
                            color="whiteIcons"
                        />
                    </span>
                </div>
            </div>
        </div>
    );
};

SearchBar.propTypes = {
    selectedTabIndex: PropTypes.number,
    displayFields: PropTypes.array,
    getSearchValue: PropTypes.func,
    intl: PropTypes.shape({
        formatMessage: PropTypes.func.isRequired
    }).isRequired,
    dataValues: PropTypes.object,
    id: PropTypes.string,
    trackWrapper: PropTypes.func.isRequired,
    getSelectFromValue: PropTypes.func
};

export default SearchBar;

