import React from 'react';
import css from './EmptyCenter.scss';
import PropTypes from 'prop-types';
import { fileNames } from 'Constants/imageUrls';
import { SVG_TYPE } from 'Constants/images';
import { FormattedMessage as Translation } from 'react-intl';
import ImageWrapper from 'Components/ImageWrapper/ImageWrapper';
import Button from 'Components/Button/Button';
import classnames from 'classnames';

const EmptyCenter = ({ title, subtitle, onButtonClick, buttonText, btnType, imageName, dataAutId = '', imgClassName = '', emptyClassName = '', titleClassName = '', subtitleClassName = '', buttonClassName = '' }) => {
    return (
        <div className={ classnames(css.emptyCenter, { [emptyClassName]: emptyClassName }) }>
            <ImageWrapper
                fileName={ imageName }
                types={ SVG_TYPE }
                alt="error occur"
                className={ classnames(css.image, { [imgClassName]: imgClassName }) } />
            {title && <p className={ classnames(css.title, { [titleClassName]: titleClassName }) }><Translation id={ title } /></p>}
            {subtitle && <p className={ classnames(css.subtitle, { [subtitleClassName]: subtitleClassName }) }><Translation id={ subtitle } /></p>}
            {buttonText && <Button
                onClick={ onButtonClick }
                className={ classnames(css.buttonInsp, { [buttonClassName]: buttonClassName }) }
                type={ btnType }
                data-aut-id={ dataAutId }
            ><Translation id={ buttonText } /></Button>}

        </div>
    );
};

EmptyCenter.defaultProps = {
    imageName: fileNames.EMPTY_STATE_INSP,
    btnType: 'primaryBtn'
};

EmptyCenter.propTypes = {
    btnType: PropTypes.string,
    imageName: PropTypes.string,
    onButtonClick: PropTypes.func,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    buttonText: PropTypes.string,
    dataAutId: PropTypes.string,
    emptyClassName: PropTypes.string,
    imgClassName: PropTypes.string,
    titleClassName: PropTypes.string,
    subtitleClassName: PropTypes.string,
    buttonClassName: PropTypes.string
};

export default EmptyCenter;
