/* eslint-disable react/jsx-no-bind */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import ValidatedNewTextField from 'Components/Fields/Validated/ValidatedNewTextField';
import Icon from 'Components/ThematicIcon/ThematicIcon';
import { PLATE_REGEX } from 'Constants/users';
import { fileNames } from 'Constants/imageUrls';
import DataLoader from '../DataLoader/DataLoader';
import SearchBar from '../SearchBar/SearchBar';
import EmptyCenter from '../../components/EmptyCenter/EmptyCenter';
import { DATA_AUT_IDS, FIELD_CONSTANTS, TRACKING_EVENTS, FIELDS_TRANSLATION_ID_MAPPING, NUMBER_PLATE_LENGTH } from '../constants';
import SITE_CODES from 'Constants/siteCodes';
import { IS_MOBILE } from 'Constants/device.APP_TARGET';
import { Link } from 'react-router-dom';
import { makeNavigationUrl } from '../helpers';

import css from './styles.scss';

export const renderPopularList = ({
    id,
    popularList,
    searchValue,
    selectedFormValues,
    onFieldValueClick,
    showDivider = true,
    marketConfig,
    displayFields
}) => {
    let Tag = marketConfig.get('olxAutos', 'isHandleSeoLink') ? Link : 'div';

    if (!popularList.length || searchValue) {
        return <></>;
    }

    const popList = popularList.map(({
        key,
        label,
        icon
    }) => {
        if (key === 'showAll') {
            Tag = 'div';
        }

        return (
            <Tag
                key={ key }
                className={ classnames(css.popularListItem, {
                    [css.selected]: key === selectedFormValues[id],
                    [css.showBackground]: key === 'showAll'
                }) }
                to={ makeNavigationUrl({ key, label }, id, displayFields, selectedFormValues, marketConfig) }
                onClick={ () => onFieldValueClick({ id, label, key }) }
                data-aut-id="popular-list-item"
            >
                { icon
                    ? <img
                        className={ classnames(css.popularListIcon, {
                            [css.customImgIcon]: [
                                SITE_CODES.OLX_ID,
                                SITE_CODES.OLX_CO,
                                SITE_CODES.OLX_AR
                            ].includes(marketConfig.get('siteCode'))
                        }) }
                        src={ icon }
                        alt={ label } />
                    : <div className={ css.popularListLabel }>{ label }</div> }
            </Tag>
        );
    });

    return (
        <div className={ classnames(css.popularList, {
            [css.showDivider]: showDivider
        }) }
        data-aut-id="popular-list">
            { popList }
        </div>
    );
};

const ValuationFieldsValue = ({
    isFetchingData,
    isError,
    stepDataValues: {
        list = [],
        popular_list = [],
        id,
        fieldDescription = ''
    } = {},
    isSearchRequired,
    onFieldValueClick,
    selectedFormValues,
    selectedTabIndex,
    getSelectFromValue,
    trackWrapper,
    intl,
    reloadPage,
    marketConfig,
    removeSelectedDataById,
    displayFields,
    getContactPageCtaStatus,
    onGetPriceClick
}) => {
    const plateErrorMessage = intl.formatMessage({ id: 'plateError' });
    const [filteredList, setFilteredList] = useState(list);
    const [searchValue, updateSearchValue] = useState('');
    const [numberPlateValue, setNumberPlateValue] = useState('');
    const [customError, setCustomError] = useState(null);
    const Tag = marketConfig.get('olxAutos', 'isHandleSeoLink') ? Link : 'div';

    useEffect(() => {
        return () => {
            setCustomError('');
        };
    }, [id]);

    const prevValRef = useRef();

    const getSearchValue = val => {
        updateSearchValue(val);
    };

    const dataValues = {
        [id]: {
            list
        }
    };

    const trackingZeroResults = () => {
        trackWrapper(TRACKING_EVENTS.name.VALUATION_ZERO_SEARCH_RESULT, {
            search_string: searchValue,
            field_name: id,
            select_from: getSelectFromValue(id)
        });
    };

    useEffect(() => {
        if (prevValRef.current != list) {
            updateSearchValue('');
            prevValRef.current = list;
        }

        let updatedFilteredList = list.filter(item => item.label.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()));

        if (!updatedFilteredList.length && searchValue) {
            updatedFilteredList = [list[list.length - 1]];
            trackingZeroResults();
        }

        setFilteredList(updatedFilteredList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(list), searchValue]);

    useEffect(() => {
        if (numberPlateValue && id != FIELD_CONSTANTS.PLATE_TYPE) {
            setNumberPlateValue('');
            return;
        }

        if (!numberPlateValue && selectedFormValues.plate) {
            setNumberPlateValue(selectedFormValues.plate);
        }
    }, [id, numberPlateValue, selectedFormValues]);

    const renderSearchBar = () => {
        if (!isSearchRequired) {
            return <></>;
        }

        return (
            <div className={ css.searchBar }>
                <SearchBar
                    selectedTabIndex={ selectedTabIndex }
                    getSearchValue={ getSearchValue }
                    intl={ intl }
                    dataValues={ dataValues }
                    id={ id }
                    trackWrapper={ trackWrapper }
                    getSelectFromValue={ getSelectFromValue }
                />
            </div>
        );
    };

    const renderDescription = () => {
        // NOTE: not showing description if id is brand
        if (!fieldDescription || id === FIELD_CONSTANTS.MAKE_KEY) {
            return <></>;
        }

        return (
            <div className={ css.description }>{fieldDescription}</div>
        );
    };

    const onNumberPlateChange = (...args) => {
        setNumberPlateValue(args[0]);
        setCustomError('');
        if (args[2]) {
            onFieldValueClick({
                id,
                key: args[0],
                label: args[0]
            });
        }
        else {
            removeSelectedDataById(id);
        }
    };

    const onNumberPlateFocus = e => {
        e.preventDefault();
        trackWrapper(TRACKING_EVENTS.name.VALUATION_ATTRIBUTE_SELECT, {
            field_name: FIELD_CONSTANTS.PLATE_TYPE,
            select_from: getSelectFromValue(FIELD_CONSTANTS.PLATE_TYPE)
        });
    };

    const getContactDetailsPage = () => {
        if (!numberPlateValue) {
            setCustomError(plateErrorMessage);
            return;
        }

        if (!getContactPageCtaStatus) {
            return;
        }
        setCustomError('');
        onGetPriceClick();
    };

    const getNumberPlateSection = () => {
        return (
            <div className={ css.plateFieldWrapper } data-aut-id="number-plate-section">
                <ValidatedNewTextField
                    id={ FIELD_CONSTANTS.PLATE_TYPE }
                    name={ FIELD_CONSTANTS.PLATE_TYPE }
                    autoComplete={ FIELD_CONSTANTS.PLATE_TYPE }
                    onChange={ onNumberPlateChange }
                    label={ intl.formatMessage({ id: 'plateNumber' }) }
                    regexErrorMessage={ intl.formatMessage({ id: 'plateValidateMessage' }) }
                    validateOnBlur={ false }
                    validateOnChange={ true }
                    direction={ 'ltr' }
                    placeholder={ intl.formatMessage({ id: 'platePlaceholder' }) }
                    autoFocus={ true }
                    required={ true }
                    regex={ new RegExp(PLATE_REGEX) }
                    requiredErrorMessage={ plateErrorMessage }
                    maxLength={ NUMBER_PLATE_LENGTH.MAX }
                    minLength={ NUMBER_PLATE_LENGTH.MIN }
                    onFocus={ onNumberPlateFocus }
                    validateOnMount={ false }
                    value={ numberPlateValue }
                    dataAutId="numberPlateHint"
                    error={ customError }
                />
                <div
                    data-aut-id="btnSubmit"
                    className={ css.numberPlateSubmitCta }
                    disabled={ getContactPageCtaStatus }
                    onClick={ getContactDetailsPage }
                >
                    <span>
                        <Icon
                            icon="back"
                            size={ 24 }
                            color="whiteIcons"
                        />
                    </span>
                </div>
            </div>);
    };

    const renderList = () => {
        let title;
        const translationId = FIELDS_TRANSLATION_ID_MAPPING[id];

        if (popular_list.length && translationId) {
            title = intl.formatMessage({ id: translationId });
        }

        if (id === FIELD_CONSTANTS.PLATE_TYPE) {
            return getNumberPlateSection();
        }

        const listItems = filteredList.map(({
            key,
            label
        }) => {
            return (
                <Tag
                    key={ key }
                    className={ classnames(css.listItems, {
                        [css.selected]: key === selectedFormValues[id]
                    }) }
                    to={ makeNavigationUrl({ key, label }, id, displayFields, selectedFormValues, marketConfig) }
                    onClick={ () => onFieldValueClick({ id, key, label }) }
                    data-aut-id="field-value-list-item">
                    { label }
                </Tag>
            );
        });

        return (
            <div className={ css.list } data-aut-id="field-value-list">
                <div className={ classnames(css.listTitle, {
                    [css['listTitle--mobile']]: IS_MOBILE
                }) }>{ title }</div>
                { listItems }
            </div>
        );
    };

    if (isFetchingData) {
        return (<div className={ css.wrapper } data-aut-id={ `${id}-form-values` }>
            <DataLoader /></div>)
        ;
    }

    if (isError) {
        const errorImg = marketConfig.get('sellAssets', 'genericError');
        const staticAssetsUrl = marketConfig.get('staticAssets');
        let imageName = '';

        if (errorImg) {
            imageName = `${staticAssetsUrl}${errorImg}`;
        }

        return (<div className={ css.errorWrapper } data-aut-id="error-view">
            <EmptyCenter
                // eslint-disable-next-line no-console
                onButtonClick={ reloadPage }
                title="oops"
                subtitle="reviews_error_desc"
                buttonText="retry"
                dataAutId={ DATA_AUT_IDS.RETRY_BTN }
                imageName={ imageName || fileNames.EMPTY_STATE_INSP }
            />
        </div>);
    }

    return (<div className={ css.wrapper } data-aut-id={ `${id}-form-values` }>
        { renderDescription() }
        { renderSearchBar() }
        { renderPopularList({
            id,
            searchValue,
            selectedFormValues,
            onFieldValueClick,
            popularList: popular_list,
            marketConfig,
            displayFields
        }) }
        { renderList() }
    </div>);
};

ValuationFieldsValue.propTypes = {
    isFetchingData: PropTypes.bool,
    isError: PropTypes.bool,
    stepDataValues: PropTypes.shape({
        list: PropTypes.array,
        popular_list: PropTypes.array,
        id: PropTypes.string,
        fieldDescription: PropTypes.string
    }),
    isSearchRequired: PropTypes.bool,
    onFieldValueClick: PropTypes.func,
    selectedFormValues: PropTypes.object,
    selectedTabIndex: PropTypes.number,
    intl: PropTypes.object,
    marketConfig: PropTypes.object,
    getSelectFromValue: PropTypes.func,
    trackWrapper: PropTypes.func.isRequired,
    reloadPage: PropTypes.func,
    removeSelectedDataById: PropTypes.func,
    displayFields: PropTypes.array,
    getContactPageCtaStatus: PropTypes.bool,
    onGetPriceClick: PropTypes.func
};

export default ValuationFieldsValue;
