/* eslint-disable react/jsx-no-bind */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import FieldsNavigationBar from '../FieldsNavigationBar/FieldsNavigationBar.desktop';
import NumberPlate from '../NumberPlate/NumberPlate';
import { TabsView } from '../ValuationFlowWidgetV2/ValuationFlowWidgetV2';
import { DEFAULT_SELECTED_TAB, TRACKING_EVENTS, VALUATION_CAR_REGISTRATION_NO, VALUATION_TABS } from '../constants';

import css from './ValuationFlowView.desktop.scss';

const ValuationFlowView = props => {
    const {
        valuationProps: {
            title,
            navItems,
            selectedFormValues,
            stepDataValues,
            isFetchingData,
            isError,
            isSearchRequired,
            selectedTabIndex,
            goToStep,
            goToNextStep,
            getSelectFromValue,
            reloadPage,
            registrationNumber,
            numberPlatePreApiCallback,
            numberPlatepostApiCallback,
            setRegistrationNumber,
            isFetchingRcData,
            trackWrapper,
            removeSelectedDataById,
            onGetPriceClick,
            tabs,
            defaultTab,
            showSubmenu,
            toggleShowSubmenu,
            displayFields
        },
        intl,
        marketConfig,
        localStorage
    } = props;

    const trackingMap = {
        car_details: TRACKING_EVENTS.page.carDetails,
        rc_number: VALUATION_CAR_REGISTRATION_NO
    };
    const [selectedTab, setSelectedTab] = useState(defaultTab || tabs[0]?.id);

    const onTabClick = id => {
        setSelectedTab(id);
        const previousSelectedTabId = localStorage.getItem(DEFAULT_SELECTED_TAB) || defaultTab;

        localStorage.setItem(DEFAULT_SELECTED_TAB, id);
        trackWrapper(TRACKING_EVENTS.name.VALUATION_TAB_ATTRIBUTE,
            {
                select_from: trackingMap[previousSelectedTabId],
                field_name: previousSelectedTabId
            }
        );
    };

    const trackPageOpenInModal = id => {
        trackWrapper(TRACKING_EVENTS.name.VALUATION_PAGE_OPEN,
            { select_from: getSelectFromValue(id) }
        );
    };

    const numberPlatepostApiCallbackHandler = res => {
        if (res.ok) {
            setSelectedTab(VALUATION_TABS.CAR_DETAILS);
            localStorage.setItem(DEFAULT_SELECTED_TAB, VALUATION_TABS.CAR_DETAILS);
        }
        numberPlatepostApiCallback(res);
    };

    const renderTabContent = () => {
        if (selectedTab === 'rc_number') {
            return (
                <NumberPlate
                    registrationNumber={ registrationNumber }
                    setRegistrationNumber={ setRegistrationNumber }
                    intl={ intl }
                    numberPlatePreApiCallback={ numberPlatePreApiCallback }
                    numberPlatePostApiCallback={ numberPlatepostApiCallbackHandler }
                    getSelectFromValue={ getSelectFromValue }
                    track={ trackWrapper }
                    marketConfig={ marketConfig }
                    isFetchingRcData={ isFetchingRcData }
                />
            );
        }

        return (
            <FieldsNavigationBar { ...{
                ...{
                    navItems,
                    selectedTabIndex,
                    selectedFormValues,
                    onNavItemClick: goToStep,
                    onGetPriceClick,
                    trackPageOpenInModal,
                    showSubmenu,
                    toggleShowSubmenu,
                    submenu: {
                        isFetchingData,
                        isError,
                        stepDataValues,
                        isSearchRequired,
                        onFieldValueClick: goToNextStep,
                        selectedFormValues,
                        selectedTabIndex,
                        stepTitle: title,
                        trackWrapper,
                        getSelectFromValue,
                        removeSelectedDataById,
                        reloadPage,
                        intl,
                        marketConfig,
                        displayFields
                    }
                }
            } } />
        );
    };

    return (
        <div className={ css.desktopWrapper }>
            {/* Tabs */}
            <div className={ css.tabsView }>
                <TabsView
                    tabs={ tabs }
                    selectedTab={ selectedTab }
                    onTabClick={ onTabClick }
                />
            </div>
            { renderTabContent() }
        </div>
    );
};

ValuationFlowView.propTypes = {
    valuationProps: PropTypes.object.isRequired,
    intl: PropTypes.object,
    marketConfig: PropTypes.object,
    router: PropTypes.object,
    localStorage: PropTypes.object
};

export default ValuationFlowView;
