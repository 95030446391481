export const SI_DATA = 'selfInspectionData';
export const SI_WHATSAPP_LINK = 'https://api.whatsapp.com/send?phone=';
export const SI_VALUATION_NAME = 'EVAL_ST';
export const SI_VALUATION_VALUE = 'VALUATION_DONE';
export const SI_VALUATION_FIELDS_MAP = 'inspectionFieldsMap';
export const SI_FLOW_START_COOKIE = 'SI_INIT';
export const SI_LEAD_CREATED = 'SI_LEAD_CREATED';

export const SI_DATA_AUT_IDS = {
    SI_TITLE: 'si_title',
    SI_SUB_TITLE: 'si_sub_title',
    SI_TY_TITLE: 'si_ty_title',
    SI_TY_SUB_TITLE: 'si_ty_sub_title',
    SI_TITLE_INDIA: 'si_title_india'
};

export const SI_ATTRIBUTE_PREFIX = 'si_';
export const SI_TRACKING_EVENTS = {
    SI_TAP_TABS: 'self_inspection_tap_questions_category',
    SI_TAP_BACK: 'self_inspection_tap_back',
    SI_TAP_CLOSE: 'self_inspection_tap_close',
    SI_ATTRIBUTE_COMPLETE: 'self_inspection_attribute_complete',
    SI_ATTRIBUTE_SELECT: 'self_inspection_attribute_select',
    SI_TAP_CONTINUE: 'self_inspection_tap_continue',
    SI_TAP_GO_BACK: 'self_inspection_tap_go_back',
    SI_TAP_CALL_CENTER: 'self_inspection_tap_call_center',
    SI_SUCCESS: 'self_inspection_successful',
    SI_ERROR_SHOWN: 'self_inspection_error_shown',
    SI_PAGE_OPEN: 'self_inspection_page_open'
};

export const SI_MISC_VALUES = {
    COMPLETED: 'completed',
    REJECTED: 'rejected'
};

export const LEAD_ID = 'lead_id';
